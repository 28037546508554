import { React } from "react";
import { useTranslation } from "react-i18next";
import Section from "../../components/Section/Section";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Collage from "../../components/Collage/Collage";
import ScrollToTopButton from "../../components/ScrollToTopButton/ScrollToTopButton";
import "./Vinyls.css";
import SpacedText from "../../components/SpacedText/SpacedText";
import { data } from "../../vinyls.js";

const Vinyls = () => {
  const { t } = useTranslation();

  const VinylsContent = () => {
    return (
      <>
        <div className="vinylsContainer">
          <p className="personality">{t("VINYLSCONTENT")}</p>
        </div>
        <Collage items={data}></Collage>
      </>
    );
  };
  return (
    <>
      <Navbar></Navbar>
      <Section
        title={t("VINYLS")}
        content={<VinylsContent></VinylsContent>}
      ></Section>
      <ScrollToTopButton></ScrollToTopButton>
      <Footer></Footer>
    </>
  );
};

export default Vinyls;
