import React from "react";
import "./Artists.css";
import { useTranslation } from "react-i18next";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Section from "../../components/Section/Section";
import SectionArtist from "../../components/SectionArtist/SectionArtist";
import ScrollToTopButton from "../../components/ScrollToTopButton/ScrollToTopButton";
const Artists = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      <Section title={t("ARTISTSPAGETITLE")} content={<SectionArtist />} />
      <ScrollToTopButton></ScrollToTopButton>
      <Footer></Footer>
    </>
  );
};

export default Artists;
