import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Artist.css";
import "bootstrap/dist/css/bootstrap.css";

const Artist = ({ artist }) => {
  const { t } = useTranslation();
  const [small, setSmall] = useState(false);

  const checkSmall = () => {
    setSmall(window.innerWidth > 1090);
  };

  useEffect(() => checkSmall, []);

  window.addEventListener("resize", checkSmall);

  return (
    <>
      <div class="row w-100 artistCard">
        <div>
          <Link to={`/artists/${artist.id}`}>
            <img src={artist.imagen} alt="" srcSet="" />
          </Link>
        </div>

        {small ? (
          <div>
            <Link to={`/artists/${artist.id}`}>
              <p>{artist.nombre}</p> <br />
              <p>{t(artist.descripcion)}</p>
            </Link>
          </div>
        ) : (
          <div>
            <Link to={`/artists/${artist.id}`}>
              <p>{artist.nombre}</p> <br />
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default Artist;
