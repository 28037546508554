import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import SpacedText from "../../components/SpacedText/SpacedText";
import Footer from "../../components/Footer/Footer";
import ScrollToTopButton from "../../components/ScrollToTopButton/ScrollToTopButton";
import "./Contact.css";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();

  const ContactContent = () => {
    return (
      <>
        <SpacedText content={t("CONTACTCONTENT")}></SpacedText>
        <div className="contactLinks">
          <a
            href="https://www.instagram.com/museoflamencopop_cathyclaret/"
            target="_blank"
          >
            <img src="/images/Instagram.png" alt={t("INSTAGRAM")} />
            {t("INSTAGRAM")}
          </a>
          <a
            href="https://open.spotify.com/playlist/109m5lycEQFXadDp50BLgq?si=8Ed84I3WQ-CY32Fu2OnUPw"
            target="_blank"
          >
            <img src="/images/Spotify.png" alt={t("SPOTIFY")} />
            {t("SPOTIFY")}
          </a>
        </div>
      </>
    );
  };

  return (
    <>
      <Navbar />
      <Section
        title={t("CONTACT")}
        content={<ContactContent></ContactContent>}
      ></Section>
      <ScrollToTopButton></ScrollToTopButton>
      <Footer />
    </>
  );
};

export default Contact;
