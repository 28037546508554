import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { data } from "../../artists";
import "./SectionArtist.css";
import Artist from "../Artist/Artist";
import Searcher from "../Searcher/Searcher";
import "bootstrap/dist/css/bootstrap.css";

const SectionArtist = () => {
  const { t } = useTranslation();
  const [dataArtist, setDataArtist] = useState([]);

  const handleChange = (e, query) => {
    e.preventDefault();
    let totalArtist = [...data];
    if (query !== " " && query !== "") {
      let artistFiltered = totalArtist.filter((artist) =>
        artist.nombre
          .toLocaleLowerCase()
          .replace(" ", "")
          .includes(query.toLocaleLowerCase().replace(" ", ""))
      );
      setDataArtist(artistFiltered);
    } else {
      setDataArtist(totalArtist);
    }
  };

  useEffect(() => {
    setDataArtist(data);
  }, []);
  return (
    <>
      <div className="sectionContainerCard">
        <div class="container-fluid mt-4  border-top border-bottom border-2  d-flex justify-content-center flex-wrap w-100">
          <div className="artistContainer">
            <p className="personality">{t("ARTISTSPAGEDESCRIPTION")}</p>
          </div>

          <div class="row w-100">
            <Searcher handleChange={handleChange}></Searcher>
          </div>
          {/* <div class=" row justify-content-center align-items-center p-5"> */}
          {dataArtist.map((artist, index) =>
            artist.visible === true ? (
              <Artist artist={artist} key={index}></Artist>
            ) : null
          )}
          {/* </div> */}
        </div>
      </div>{" "}
    </>
  );
};
export default SectionArtist;
