export const data = [
  {
    id: 1,
    link: "https://www.vogue.es/living/articulos/museo-flamenco-pop-itinerante-cathy-claret",
    title:
      "Nace el Museo del Flamenco Pop, una exposición itinerante con la que recordar y celebrar la innovación de los artistas gitanos",
    image: "/images/portadaLink5.png",
    litleinfo:
      "Barcelona acogió la inauguración del proyecto, comisariado por la compositora Cathy Claret, que ahora viaja a otras ciudades y estrena su versión digital para llevar la historia de las innovaciones de los artistas gitanos a todo aquel que esté interesado en la historia del flamenco",
  },
  {
    id: 2,
    link: "http://www.programaraices.es/2023/06/descubrimos-un-rincon-unico-el-museo.html?m=1",
    title: "DESCUBRIMOS 'UN RINCÓN' ÚNICO, EL MÚSEO DEL FLAMENCO POP",
    image: "/images/portadaLink1.png",
    litleinfo:
      "En su día se creó para gracia de la música y en gran parte del pueblo Gitano el género del Flamenco Pop, pues bien, hoy en día, gracias a sus adeptos, cómo Cathy Claret, tenemos la suerte de tener un 'rincón' único para ese estilo musical que mezcla nuestras raíces con los sones más nuevos de la mitad del Siglo XX",
  },
  {
    id: 3,
    link: "https://unionromani.org/2023/04/24/el-museo-virtual-del-flamenco-pop-llega-a-barcelona/",
    title: "El Museo Virtual del Flamenco Pop llega a Barcelona",
    image: "/images/portadaLink2.png",
    litleinfo:
      "La compositora y cantante Cathy Claret, la profesora Ana Giménez y el profesor Antonio José Díaz son algunos de los impulsores del proyecto.",
  },
  {
    id: 4,
    link: "https://www.castelloninformacion.com/nace-el-museo-del-flamenco-pop-que-se-presentara-manana-en-la-fundacion-punjab-de-castellon/",
    title:
      "Nace el Museo del Flamenco Pop, que se presentará mañana en la Fundación Punjab de Castellón",
    image: "/images/portadaLink3.png",
    litleinfo:
      "La compositora y cantante internacional Cathy Claret, la profesora Ana Giménez y el profesor Antonio José Díaz son algunos de los impulsores del proyecto",
  },
  {
    id: 5,
    link: "https://www.rtve.es/play/audios/gitanos/museo-del-flamenco-pop/6875752/",
    title: "El Museo del Flamenco Pop",
    image: "/images/portadaLink4.png",
    litleinfo:
      "Nace el Museo del Flamenco Pop, una iniciativa de la cantante y compositora Cathy Claret que recuerda y celebra la innovación y la creatividad de los artistas gitanos, y que acaba de inaugurarse en Barcelona. ",
  },
  {
    id: 6,
    link: "https://donesdigital.ingeniusds.cat/article/8428",
    title:
      "Cathy Claret reivindica a artistes que van sacsejar els estereotips del flamenc",
    image: "/images/portadaLink6.png",
    litleinfo: "Aportació contra expropiació. I rescabalament enfront d'oblit.",
  },
  {
    id: 7,
    link: "https://t.co/fR3HYjdwIV",
    title: "Calidoscopi Cultural 2024. Flamenco Pop: Una revolución gitana",
    image: "/images/portadaLink7.jpg",
    litleinfo:
      "En el marc del festival Calidoscopi Cultural 2024, l'Espai Avinyó, la Biblioteca Bon Pastor i el Servei d'Interculturalitat de Sant Andreu us proposen visitar una mostra del Museo Flamenco Pop de Cathy Claret, un homenatge virtual i físic a l'incalculable nombre d'artistes gitanes i gitanos creadors i pioners d'allò que s'ha anomenat ' Nou Flamenc '.    ",
  },
];
