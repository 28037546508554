import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import SpacedText from "../../components/SpacedText/SpacedText";
import Footer from "../../components/Footer/Footer";
import ScrollToTopButton from "../../components/ScrollToTopButton/ScrollToTopButton";
import { useTranslation } from "react-i18next";
import "../../index.css";
import "./About.css";
import { createElement } from "react";

const About = () => {
  const { t } = useTranslation();

  const AboutContent = () => {
    return (
      <>
        <Section
          title={t("ABOUTTITLE1")}
          content={<SpacedText content={t("ABOUTSECTION1")}></SpacedText>}
        ></Section>

        <Section
          title={t("ABOUTTITLE2")}
          content={<SpacedText content={t("ABOUTSECTION2")}></SpacedText>}
        ></Section>

        <Section
          title={t("ABOUTTITLE3")}
          content={<SpacedText content={t("ABOUTSECTION3")}></SpacedText>}
        ></Section>

        <Section
          title={t("ABOUTTITLE4")}
          content={<SpacedText content={t("ABOUTSECTION4")}></SpacedText>}
        ></Section>

        <div className="aboutImages">
          {/* <div>
            <img src="/images/Cathy.jpeg" alt="" />
          </div> */}
          <div>
            <img src="/images/Ana.jpg" alt="" />
          </div>
          <div>
            <img src="/images/AmariZor.jpg" alt="" />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Navbar />
      <AboutContent></AboutContent>
      <ScrollToTopButton></ScrollToTopButton>
      <Footer />
    </>
  );
};

export default About;
