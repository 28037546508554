import React from "react";
import "./ColorSection.css";
import SpacedText from "../SpacedText/SpacedText";
import { useTranslation } from "react-i18next";

const ColorSection = ({ text }) => {
  return (
    <div className="colorSectionContainer">
      <div className="colorSection">
        <SpacedText content={text}></SpacedText>
      </div>
    </div>
  );
};

export default ColorSection;
