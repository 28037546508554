export const data = [
  {
    id: 0,
    link: "/images/Vinilo37.jpeg",
    description: "LAS GRECAS: 'Gipsy Rock' 1974.",
    genero:
      "Flamenco fusión del flow gitano y el rockero que da lugar al Gipsy Rock a base sobretodo de Tangos. Un disco absolutamente rompedor y uno de los mejores de la historia.",
  },
  {
    id: 1,
    link: "/images/Vinilo38.jpeg",
    description:
      "LOS CHORBOS: 'El Sonido Caño Roto-Poder Gitano' 1975. Encuentro con la Soul de la motown, increible.",
    genero:
      "Rumba Flamenca Gitana con soul, arreglos de funk con instrumental de Manzanita y un fandango-rock de Morente.",
  },
  {
    id: 2,
    link: "/images/Vinilo34.jpeg",
    description: "REMEDIOS AMAYA: 'Remedios Amaya' 1978.",
    genero:
      "Flamenco Gitano respaldado por rockeros, violín y el sitar hindú de Gualberto. Un cruze increible de voz gitana flamenca pura con toques electronicos. Absolutamente a años luz adelantado a su época.",
  },
  {
    id: 3,
    link: "/images/Vinilo27.jpeg",
    description: "MANZANITA: 'Poco ruido y mucho duende' 1978.",
    genero:
      "Flamenco Pop. Un creador genial que puso musica a los grandes poetas Federico Garcia Lorca, Becquer, Sor Teresa mejor que nadie... Un inmenso genio que inspiro el mismisimo Sting de Police.",
  },
  { id: 4, link: "/images/Vinilo56.jpg", description: "" },
  { id: 5, link: "/images/Vinilo42.jpg", description: "" },
  { id: 6, link: "/images/Vinilo22.jpeg", description: "" },
  {
    id: 7,
    link: "/images/Vinilo30.jpeg",
    description: "EL LUIS: 'Gitano Soul' 1981.",
    genero: "Flamenco con inspiración religiosa, soul, rock y gospel. Pionero.",
  },
  {
    id: 8,
    link: "/images/Vinilo32.jpeg",
    description: "LA MARELU: 'Jaleos con la Marelu' 1974.",
    genero: "Flamenco extremeño puro, pegadizo y agradable al oído.",
  },
  {
    id: 9,
    link: "/images/Vinilo3.jpeg",
    description: "PARRITA: 'Canastero' 1990.",
    genero:
      "Rumba-Balada-Flamenco Fiestero (Tangos y Bulerías). Una de las grandes voces flamencas del siglo.",
  },
  {
    id: 10,
    link: "/images/Vinilo1.jpeg",
    description: "JEROS: 'Tembló pero no calló' 1990.",
    genero:
      "Rumba flamenca personal con incorporación de nuevos acordes. Uno de los mejores compositores gitanos.",
  },
  {
    id: 11,
    link: "/images/Vinilo2.jpeg",
    description: "CHEROKEE: 'Caballo loco' 1994.",
    genero:
      "Rumba aflamencada con ritmo de Tangos y Bulerías e incursiones en el Rock.Y unos looks muy cuidados.",
  },
  {
    id: 12,
    link: "/images/Vinilo4.jpeg",
    description:
      "EL CAMARÓN DE LA ISLA CON PACO DE LUCIA: 'Disco de oro' 1975.",
    genero:
      "Flamenco puro sin trampa ni cartón. esta en el museo de flamenco pop porque su cante mismo fue revolucionario y novedoso.",
  },
  {
    id: 13,
    link: "/images/Vinilo5.jpeg",
    description: "LA BARBERIA DEL SUR: 'La Barbería del Sur' 1991.",
    genero:
      "Nuevo Flamenco-Jóvenes Flamencos. Flamenco fusionado con jazz, balada, música latina con metales, salsa, bossa nova, rock.",
  },
  {
    id: 14,
    link: "/images/Vinilo7.jpeg",
    description: "TIJERITAS: 'Pegaso que tienes alas' 1973.",
    genero:
      "Techno rumbas aflamencadas. Baladas gitanas, cante fiestero y muy gitano. Cantaba flamenco puro como nadie tambien.",
  },
  {
    id: 15,
    link: "/images/Vinilo8.jpeg",
    description:
      "PATA NEGRA: 'Guitarras Callejeras' Año de publicación 1986 pero grabado en 1979.",
    genero:
      "Flamenco Rock Gitano (blues, rock & roll, jazz, rancheras, reggae etc...). Inventaron la Bluesleria.",
  },
  {
    id: 16,
    link: "/images/Vinilo9.jpeg",
    description: "LOS CALIS: 'Heroína / Quién te riza el pelo' 1986.",
    genero: "Rumba flamenca con letras sociales.",
  },
  {
    id: 17,
    link: "/images/Vinilo10.jpeg",
    description: "Rumba flamenca con letras sociales.",
  },
  { id: 18, link: "/images/Vinilo11.jpeg", description: "" },
  { id: 19, link: "/images/Vinilo12.jpeg", description: "" },
  { id: 20, link: "/images/Vinilo13.jpeg", description: "" },
  {
    id: 21,
    link: "/images/Vinilo14.jpeg",
    description:
      "Flamenco fusionado con Rumba y Soul apropiado para bailar en discotecas",
  },
  {
    id: 22,
    link: "/images/Vinilo15.jpeg",
    description:
      "Uno de los discos más importantes del nuevo flamenco. Un genio letras y composiciones qur quedaran por los tiempos.",
  },
  {
    id: 23,
    link: "/images/Vinilo16.jpeg",
    description:
      "Flamenco joven de la escuela Camaronera con los mejores guitarristas flamencos.",
  },
  {
    id: 24,
    link: "/images/Vinilo17.jpeg",
    description:
      " Rumba Flamenca vanguardista a base de tangos y bulerías con guitarras, percusión, violín y acordeón.",
  },
  {
    id: 25,
    link: "/images/Vinilo18.jpeg",
    description:
      "Flamenco puro fusionado con salsa, reggae y ritmos arábigos, cantado con una sensibilidad nunca oída.Un genio componiendo y una manera de xantar unica y moderna.",
  },
  {
    id: 26,
    link: "/images/Vinilo19.jpeg",
    description:
      "Flamenco Festero muy Gitano.Un verdadero terremotto adelantada a su tiempo y con letras casi punkis 'dame una copa mas'.",
  },
  {
    id: 27,
    link: "/images/Vinilo20.jpeg",
    description:
      "Flamenco, Rumba y Cantes Festeros con matices arábigos. Techno flamenco adelando a su tiempo.",
  },
  {
    id: 28,
    link: "/images/Vinilo21.jpeg",
    description: "Rumba flamenca  Made in Lloret de Mar.",
  },
  {
    id: 29,
    link: "/images/Vinilo23.jpeg",
    description:
      "Flamenco Festero del muy bueno (Tangos, Tanguillos y Bulerías) con teclados y buenos arreglos musicales.Una genia musical.Algunos la llaman la Camarona.",
  },
  {
    id: 30,
    link: "/images/Vinilo24.jpeg",
    description:
      "Rumba flamenca rock electro  transformada en música urbana con letras sociales.",
  },
  {
    id: 31,
    link: "/images/Vinilo25.jpeg",
    description: "Nuevo Flamenco Soul con salsa y ritmos latinos y voz 'Soul'.",
  },
  {
    id: 32,
    link: "/images/Vinilo26.jpeg",
    description:
      "Nuevo Flamenco fusionado con funk, baladas, soul latino y gitano",
  },
  {
    id: 33,
    link: "/images/Vinilo28.jpeg",
    description: "EL TURRONERO: 'New Hondo' 1980.",
    genero: "Flamenco con música de discoteca tipo los Bee Gees.",
  },
  {
    id: 34,
    link: "/images/Vinilo29.jpeg",
    description: "ZÍNGARO: 'Voz de Luna' 1979.",
    genero:
      "Flamenco, canción melódica y rumba con guitarra flamenca, percusiones varias y bajo eléctrico. Un poeta gitano.",
  },
  {
    id: 35,
    link: "/images/Vinilo31.jpeg",
    description: "SABICAS & JOE BECK: 'Rock Encounter' 1970.",
    genero:
      "Flamenco y Rock, gran experiencia fascinante y provocador para los puristas.",
  },
  {
    id: 36,
    link: "/images/Vinilo33.jpeg",
    description: "LAVENTA: 'Al compás del viento' 1977.",
    genero:
      "Flamenco (Fandangos, Alegrías, Bulerías) con guitarra e instrumentos ajenos al flamenco finalizando con unas bulerías de rock sinfónico. Una pionera del flamenco pop. Fue miss madrid 75 y con un look absolutamente moderno y canciones inolvidables. Demostró que el flamenco pop puede tener audiencia.",
  },
  {
    id: 37,
    link: "/images/Vinilo35.jpeg",
    description: "LAS GRECAS: 'Casta viva' 1977.",
    genero:
      "Disco donde deciden volver a los orígenes con arreglos más tradicionales.",
  },
  {
    id: 38,
    link: "/images/Vinilo36.jpeg",
    description: "LAS GRECAS: 'Mucho más' 1975.",
    genero:
      "Flamenco Gipsy Rock. Un disco absolutamente rompedor y adelantado a su época.",
  },
  {
    id: 39,
    link: "/images/Vinilo39.jpg",
    description: "Flamenco Extremeño puro, pegadizo y agradable al oído. ",
  },
  {
    id: 40,
    link: "/images/Vinilo40.jpg",
    description:
      "Flamenco (Fandangos, Alegrías, Bulerías) con guitarra y  instrumentos ajenos al flamenco finalizando con unas Bulerías de Rock Sinfónico.Una pionera del flamenco pop.Fue miss madrid 75 y con un look absolutamente moderno y xanciones inolvidables demostro que el flamenco pop puede tener audiencia ",
  },
  {
    id: 41,
    link: "/images/Vinilo41.jpg",
    description:
      "Flamenco Gitano respaldado por rockeros, violín y el sitar hindú de Gualberto.Un cruze increible fe voz gitana flamenca pura con toques electronicos.Absolutamente a años luz adelantado a su epoca.",
  },
  {
    id: 42,
    link: "/images/Vinilo43.jpg",
    description:
      "Disco donde deciden volver a los orígenes con arreglos más tradicionales.  ",
  },
  {
    id: 43,
    link: "/images/Vinilo44.jpg",
    description:
      "Flamenco Gipsy Rock. Un disco absolutamente rompedor y adelantado a su epoca.",
  },
  {
    id: 44,
    link: "/images/Vinilo45.jpg",
    description:
      "Flamenco fusión del flow gitano y el rockero que da lugar al Gipsy Rock a base sobretodo de Tangos. Un disco absolutamente rompedor y uno de los mejores de la historia.",
  },
  {
    id: 45,
    link: "/images/Vinilo46.jpg",
    description:
      "Rumba Flamenca Gitana con soul, arreglos de funk con instrumental de Manzanita, y un fandango-rock de Morente.",
  },
  { id: 46, link: "/images/Vinilo47.jpg", description: "" },
  { id: 47, link: "/images/Vinilo48.jpg", description: "" },
  { id: 48, link: "/images/Vinilo49.jpg", description: "" },
  { id: 49, link: "/images/Vinilo50.jpg", description: "" },
  { id: 50, link: "/images/Vinilo51.jpg", description: "" },
  { id: 51, link: "/images/Vinilo52.jpg", description: "" },
  { id: 52, link: "/images/Vinilo53.jpg", description: "" },
  { id: 53, link: "/images/Vinilo54.jpg", description: "" },
  { id: 54, link: "/images/Vinilo55.jpg", description: "" },
  { id: 55, link: "/images/Vinilo57.jpg", description: "" },
  { id: 56, link: "/images/Vinilo58.jpg", description: "" },
  { id: 57, link: "/images/Vinilo59.jpg", description: "" },
  { id: 58, link: "/images/Vinilo60.jpg", description: "" },
  { id: 59, link: "/images/Vinilo61.jpg", description: "" },
  { id: 60, link: "/images/Vinilo62.jpg", description: "" },
  { id: 61, link: "/images/Vinilo63.jpg", description: "" },
  { id: 62, link: "/images/Vinilo64.jpg", description: "" },
  { id: 63, link: "/images/Vinilo65.jpg", description: "" },
  { id: 64, link: "/images/Vinilo66.jpg", description: "" },
  { id: 65, link: "/images/Vinilo67.jpg", description: "" },
  { id: 66, link: "/images/Vinilo68.jpg", description: "" },
  { id: 67, link: "/images/Vinilo69.jpg", description: "" },
  { id: 68, link: "/images/Vinilo70.jpg", description: "" },
  { id: 69, link: "/images/Vinilo71.jpg", description: "" },
  { id: 70, link: "/images/Vinilo72.jpg", description: "" },
];
