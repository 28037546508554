import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./Searcher.css";

const Searcher = ({ handleChange }) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");

  const handleQuery = (event) => {
    setQuery(event.target.value);
    handleChange(event, event.target.value);
  };

  return (
    <div className="row w-100 d-flex justify-content-center flex-wrap">
      <form
        className="search-bar d-flex flex-wrap gap-3"
        onSubmit={(e) => handleChange(e, query)}
      >
        <input
          type="text"
          placeholder={t("SEARCHPLACEHOLDER")}
          value={query}
          onChange={handleQuery}
          className="search-bar__input w-50"
        />
        <button type="submit" className="search-bar__button">
          {t("SEARCH")}
        </button>
      </form>
    </div>
  );
};

export default Searcher;
