import React from "react";
import { useTranslation } from "react-i18next";
import Section from "../../components/Section/Section";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Sectionevents from "../../components/SectionEvents/Sectionevents";
import ScrollToTopButton from "../../components/ScrollToTopButton/ScrollToTopButton";
import "./Events.css";

const Events = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar></Navbar>
      <Sectionevents></Sectionevents>
      <ScrollToTopButton></ScrollToTopButton>
      <Footer></Footer>
    </>
  );
};

export default Events;
