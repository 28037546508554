import React from "react";
import { useTranslation } from "react-i18next";
import Section from "../../components/Section/Section";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import ScrollToTopButton from "../../components/ScrollToTopButton/ScrollToTopButton";
import "./News.css";
import { data } from "../../newslink";

const News = () => {
  const { t } = useTranslation();

  const Links = ({ data }) => {
    return (
      <div className="newsPage">
        <div className="newsContainer">
          <p className="personality">{t("NEWSCONTENT")}</p>
        </div>
        <div className="news">
          <ul>
            {data.map((item, index) => (
              <li key={index}>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <h4> {item.title}</h4>
                </a>
                <h5>{item.litleinfo}</h5>
                <img src={item.image} alt="no imagen" />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <>
      <Navbar></Navbar>
      <Section
        title={t("NEWS")}
        content={<Links data={data}></Links>}
      ></Section>
      <ScrollToTopButton></ScrollToTopButton>
      <Footer></Footer>
    </>
  );
};

export default News;
