import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SpacedText from "../SpacedText/SpacedText";
import "./Card.css";
import "bootstrap/dist/css/bootstrap.css";

const Card = ({ artist }) => {
  const { t, i18n } = useTranslation();

  const cutDescription = (asrtist) => {
    let currentLanguage = i18n.language;
    let text = t(artist.descripcion);
    let myText = "";

    if (currentLanguage === "en") {
      myText = text.substring(0, 130);
    } else if (currentLanguage === "es") {
      myText = text.substring(0, 130);
    } else if (currentLanguage === "ja") {
      myText = text.substring(0, 80);
    }
    return myText.concat("...");
  };

  return (
    <>
      <div class="col-md-4 m-2 text-center d-flex justify-content-center align-content-center">
        <div class="myCard">
          <div class="innerCard">
            <div class="frontSide">
              <img src={artist.imagen} alt="no imagen" />
              <p class="title">{artist.nombre}</p>
            </div>
            <div class="backSide">
              <p class="title">{t("DESCRIPTION")}</p>
              <p>{cutDescription(artist)}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Card;
