export const data = [
  {
    id: 1,
    image: "images/eventoCastellonMarzo23.jpeg",
    date: "03/30/2023",
    city: "Castellon",
  },
  {
    id: 2,
    image: "images/eventoBarcelonaAbril23.jpeg",
    date: "04/04/2023",
    city: "Barcelona",
  },
  {
    id: 3,
    image: "images/eventoGironaNoviembre23.jpg",
    date: "11/11/2023",
    city: "Girona",
  },
];
