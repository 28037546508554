import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import Home from "./Views/Home/Home.jsx";
import About from "./Views/About/About.jsx";
import Contact from "./Views/Contact/Contact";
import Artists from "./Views/Artists/Artists";
import ArtistDetail from "./Views/ArtistDetail/ArtistDetail";
import Events from "./Views/Events/Events";
import Vinyls from "./Views/Vinyls/Vinyls";
import News from "./Views/News/News";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./i18n/en.json";
import es from "./i18n/es.json";
import ja from "./i18n/ja.json";
import { Analytics } from "@vercel/analytics/react";
import { useEffect } from "react";
i18next.use(initReactI18next).init({
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    es: {
      translation: es,
    },
    en: {
      translation: en,
    },
    ja: {
      translation: ja,
    },
  },
});

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Realizar la redirección a la ruta deseada después de algún tiempo o en respuesta a un evento
    const redirectionTimer = setTimeout(() => {
      navigate("/");
    }, 0); // Redirigir después de 1 segundo, ajusta según tus necesidades

    // Limpia el temporizador al desmontar el componente
    return () => clearTimeout(redirectionTimer);
  }, [navigate]);

  return <></>;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <h1>Error</h1>,
  },
  {
    path: "/about",
    element: <About />,
    errorElement: <h1>Error</h1>,
  },
  {
    path: "/contact",
    element: <Contact />,
    errorElement: <h1>Error</h1>,
  },
  {
    path: "/artists",
    element: <Artists />,
    errorElement: <h1>Error</h1>,
  },
  {
    path: "/artists/:id",
    element: <ArtistDetail></ArtistDetail>,
    errorElement: <h1>Error</h1>,
  },
  {
    path: "/events",
    element: <Events />,
    errorElement: <h1>Error</h1>,
  },
  {
    path: "/news",
    element: <News />,
    errorElement: <h1>Error</h1>,
  },
  {
    path: "/vinyls",
    element: <Vinyls />,
    errorElement: <h1>Error</h1>,
  },
  {
    path: "/*",
    element: <NotFound />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}></RouterProvider>
    <Analytics></Analytics>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
