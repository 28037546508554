import { React, useState } from "react";
import "./Collage.css";
import "bootstrap/dist/css/bootstrap.css";
import { useTranslation } from "react-i18next";
const Collage = ({ items }) => {
  const { t, i18n } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const abrirModal = (item) => {
    setSelectedItem(item);
    setModalOpen(true);
  };

  // Función para cerrar el modal
  const cerrarModal = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };
  const cutDescription = (description) => {
    let currentLanguage = i18n.language;
    // let text = t(description);
    let text = description;
    let myText = "";

    if (currentLanguage === "en") {
      myText = text.substring(0, 80);
    } else if (currentLanguage === "es") {
      myText = text.substring(0, 80);
    } else if (currentLanguage === "ja") {
      myText = text.substring(0, 15);
    }
    return description.length ? myText.concat("...") : "";
  };

  return (
    <div className="collage-container">
      {items.map((item, index) => (
        <div
          key={index}
          className="collage-vinyl"
          onClick={() => abrirModal(item)}
        >
          <img className="collage-item" src={item.link} alt="no imagen" />
          {/* <p className="collage-description">
            {cutDescription(item.description)}
          </p> */}
        </div>
      ))}

      {modalOpen && selectedItem && (
        <div className="modal">
          <img src={selectedItem.link} alt="no imagen" />
          <p>{selectedItem.description}</p>
          <p>{selectedItem.genero}</p>
          <button onClick={cerrarModal}>Cerrar</button>
        </div>
      )}
    </div>
  );
};

export default Collage;
