import React from "react";
import "./Videography.css";

const Videography = ({ data }) => {
  const getVideoId = (link) => {
    const url = new URL(link);
    return url.pathname.substr(1);
  };

  return (
    <>
      <div className="videographyContainer">
        {data.videografia.map((link, index) =>
          !index ? (
            <iframe className="mainVideo" src={link} frameborder="0"></iframe>
          ) : (
            <iframe src={link} frameborder="0"></iframe>
          )
        )}
      </div>
    </>
  );
};

export default Videography;
