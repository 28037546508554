import React, { useState, useEffect } from "react";
import "./Slideshow.css";

const Slideshow = ({ images, intervalTime }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const PrevButton = () => {
    return (
      <div>
        <button className="prev-button" onClick={goToPreviousImage}>
          &lt;
        </button>
      </div>
    );
  };

  const NextButton = () => {
    return (
      <div>
        <button className="next-button" onClick={goToNextImage}>
          &gt;
        </button>
      </div>
    );
  };

  // const checkSize = () => {
  //   if (window.innerWidth <= 768) {
  //     try {
  //       document.querySelector(".slideContainer").children[0].remove();
  //       // console.log(document.querySelector(".prev-button").parentElement);
  //       document.querySelector(".slideContainer").children[2].remove();
  //     } catch (error) {}
  //   } else {
  //     if (document.querySelector(".slideContainer").children < 2) {
  //     }
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", checkSize);
  //   checkSize();
  // }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, intervalTime);

    return () => clearInterval(timer);
  }, [images, intervalTime]);

  const goToPreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <>
      <div className="slideContainer">
        {window.innerWidth > 768 ? <PrevButton></PrevButton> : ""}

        <div className="slideshow">
          <img src={images[currentImageIndex]} />
        </div>
        {window.innerWidth > 768 ? <NextButton></NextButton> : ""}
      </div>

      <div className="indicators">
        {images.map((_, index) => (
          <div
            key={index}
            className={`indicator ${
              index === currentImageIndex ? "active" : ""
            }`}
            onClick={() => setCurrentImageIndex(index)}
          ></div>
        ))}
      </div>
    </>
  );
};

export default Slideshow;
