import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./Sectionevents.css";
import { data } from "../../eventsMuseum";
import Flickity from "react-flickity-component";
import "./flickity.css";

const Sectionevents = () => {
  const { t } = useTranslation();

  let finished = [];
  let earring = [];
  if (data.length) {
    data.forEach((x) => {
      let date = Date.parse(x.date);
      let compareDate = Date.now();

      if (date < compareDate) {
        finished.push(x);
      } else {
        earring.push(x);
      }
    });
  }

  finished.sort((a, b) => a.id - b.id);
  earring.sort((a, b) => a.id - b.id);

  const flickityOptions = {
    initialIndex: 0,
    adaptiveHeight: true,
    fade: true,
    wrapAround: true,
  };
  return (
    <>
      <div className="CarrouselFlickity">
        <h1 className="personality"> Finished Events</h1>
        <Flickity options={flickityOptions}>
          {finished.map((item) => (
            <figure key={item.id}>
              <img src={item.image} alt={`${item.id}`} />
              <figcaption>{item.city}</figcaption>
            </figure>
          ))}
        </Flickity>

        {earring.length > 0 ? (
          <>
            <hr />
            <h1 className="personality"> Earring Events</h1>
            <Flickity>
              {earring.map((item) => (
                <figure key={item.id}>
                  <img src={item.image} alt={`${item.id}`} />
                  <figcaption>{item.city}</figcaption>
                </figure>
              ))}
            </Flickity>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Sectionevents;
