export const data = [
  {
    id: 1,
    nombre: "Los Chorbos",
    imagen: "/images/loschorbos.png",
    eslogan: "CHORBOSESLOGAN",
    descripcion: "CHORBOSDESCRIPTION",
    biografia: "CHORBOSBIOGRAPHY",
    discografia: [
      "/images/loschorbos/disco1.jpg",
      "/images/loschorbos/disco2.jpg",
      "/images/loschorbos/disco3.jpg",
      "/images/loschorbos/disco4.jpg",
      "/images/loschorbos/disco5.jpg",
      "/images/loschorbos/disco6.jpg",
    ],
    videografia: [
      "https://www.youtube.com/embed/fQrkM2IRBMA",
      "https://www.youtube.com/embed/GZJ7QpSzVmM",
      "https://www.youtube.com/embed/FfrJ1-nEs4w",
    ],
    visible: true,
  },
  {
    id: 2,
    nombre: "Las Grecas",
    imagen: "/images/lasgrecas.jpg",
    eslogan: "GRECASESLOGAN",
    descripcion: "GRECASDESCRIPTION",
    biografia: "GRECASBIOGRAPHY",
    discografia: [
      "/images/loschorbos/disco1grecas.png",
      "/images/loschorbos/disco2grecas.png",
      "/images/loschorbos/disco3grecas.png",
    ],
    videografia: [
      "https://www.youtube.com/embed/BFTzX_QzAwQ",
      "https://www.youtube.com/embed/gYv8QglFdGE",
      "https://www.youtube.com/embed/YUltU-1Fvv8",
      "https://www.youtube.com/embed/df1XaO7KAFs",
    ],
    visible: true,
  },
  {
    id: 3,
    nombre: "Remedios Amaya",
    imagen: "/images/remediosamaya.jpg",
    eslogan: "REMEDIOSAMAYAESLOGAN",
    descripcion: "REMEDIOSAMAYADESCRIPTION",
    biografia: "REMEDIOSAMAYABIOGRAPHY",
    discografia: [
      "/images/loschorbos/disco1remediosamaya.jpg",
      "/images/loschorbos/disco2remediosamaya.jpg",
      "/images/loschorbos/disco3remediosamaya.jpg",
    ],
    videografia: [
      "https://www.youtube.com/embed/B3-0DLbQP2E",
      "https://www.youtube.com/embed/cmeirqyK6g0",
      "https://www.youtube.com/embed/5n3YMnKJISo",
    ],
    visible: true,
  },
  {
    id: 4,
    nombre: "Ray Heredia",
    imagen: "/images/rayheredia.jpg",
    eslogan: "RAYHEREDIAESLOGAN",
    descripcion: "RAYHEREDIADESCRIPTION",
    biografia: "RAYHEREDIABIOGRAPHY",
    discografia: ["/images/loschorbos/disco1rayheredia.jpg"],
    videografia: [
      "https://www.youtube.com/embed/LNxdLyYAgrg",
      "https://www.youtube.com/embed/YGcY-P6GtZk",
      "https://www.youtube.com/embed/yA8ZfrJvSu4",
    ],
    visible: true,
  },
  {
    id: 5,
    nombre: "Las Chuches",
    imagen: "/images/laschuches.jpg",
    eslogan: "CHUCHESESLOGAN",
    descripcion: "CHUCHESDESCRIPTION",
    biografia: "CHUCHESBIOGRAPHY",
    discografia: [
      "/images/loschorbos/disco1laschuches.jpg",
      "/images/loschorbos/disco2laschuches.jpg",
      "/images/loschorbos/disco3laschuches.jpg",
    ],
    videografia: [
      "https://www.youtube.com/embed/uqlhZhSAAG8",
      "https://www.youtube.com/embed/kpgT7RzSf4s",
      "https://www.youtube.com/embed/shPZH-JFtsA",
      "https://www.youtube.com/embed/BhJGkN1JMdg",
    ],
    visible: true,
  },
  {
    id: 6,
    nombre: "Los Chichos",
    imagen: "/images/loschichos.jpg",
    eslogan: "CHICHOSESLOGAN",
    descripcion: "CHICHOSDESCRIPTION",
    biografia: "CHICHOSBIOGRAPHY",
    discografia: [
      "/images/loschorbos/disco1loschichos.jpg",
      "/images/loschorbos/disco2loschichos.jpg",
      "/images/loschorbos/disco3loschichos.jpg",
    ],
    videografia: [
      "https://www.youtube.com/embed/kZBzFOd9nwI",
      "https://www.youtube.com/embed/PMC6RayzVV0",
      "https://www.youtube.com/embed/kZBzFOd9nwI",
    ],
    visible: true,
  },
  {
    id: 7,
    nombre: "Lole y Manuel",
    imagen: "/images/loleymanuel.jpg",
    eslogan: "LOLEMANUELESLOGAN",
    descripcion: "LOLEMANUELDESCRIPTION",
    biografia: "LOLEMANUELBIOGRAPHY",
    discografia: [
      "/images/loschorbos/disco1lolemanuel.jpg",
      "/images/loschorbos/disco2lolemanuel.jpg",
      "/images/loschorbos/disco3lolemanuel.jpg",
    ],
    videografia: [
      "https://www.youtube.com/embed/lkTbvj_xFcU",
      "https://www.youtube.com/embed/OGVo4NWenfI",
      "https://www.youtube.com/embed/rs99l036-B4",
      "https://www.youtube.com/embed/SudbXjfXpJ4",
    ],
    visible: true,
  },
  {
    id: 8,
    nombre: "Manzanita",
    imagen: "/images/manzanita.jpg",
    eslogan: "MANZANITAESLOGAN",
    descripcion: "MANZANITADESCRIPTION",
    biografia: "MANZANITABIOGRAPHY",
    discografia: [
      "/images/loschorbos/disco1manzanita.jpg",
      "/images/loschorbos/disco2manzanita.jpg",
      "/images/loschorbos/disco3manzanita.jpg",
    ],
    videografia: ["https://www.youtube.com/embed/ploZF4cy59o"],
    visible: true,
  },
  {
    id: 9,
    nombre: "Susi",
    imagen: "/images/susi.jpg",
    eslogan: "SUSIESLOGAN",
    descripcion: "SUSIDESCRIPTION",
    biografia: "SUSIBIOGRAPHY",
    discografia: [
      // "url_imagen_album1.jpg",
      // "url_imagen_album2.jpg",
      // "url_imagen_album3.jpg",
    ],
    videografia: [
      // "https://www.youtube.com/watch?v=video_id1",
      // "https://www.youtube.com/watch?v=video_id2",
      // "https://www.youtube.com/watch?v=video_id3",
    ],
    visible: true,
  },
  {
    id: 10,
    nombre: "Marelu",
    imagen: "/images/marelu.jpg",
    eslogan: "MARELUESLOGAN",
    descripcion: "MARELUDESCRIPTION",
    biografia: "MARELUBIOGRAPHY",
    discografia: [
      // "url_imagen_album1.jpg",
      // "url_imagen_album2.jpg",
      // "url_imagen_album3.jpg",
    ],
    videografia: [
      // "https://www.youtube.com/watch?v=video_id1",
      // "https://www.youtube.com/watch?v=video_id2",
      // "https://www.youtube.com/watch?v=video_id3",
    ],
    visible: true,
  },
  {
    id: 11,
    nombre: "Amina",
    imagen: "/images/amina.jpg",
    descripcion: "AMINADESCRIPTION",
    eslogan: "AMINAESLOGAN",
    biografia: "AMINABIOGRAPHY",
    discografia: [
      "/images/loschorbos/Disco1Amina.jpg",
      "/images/loschorbos/Disco2Amina.jpg",
      // "url_imagen_album3.jpg",
    ],
    videografia: [
      "https://www.youtube.com/embed/xh54B2ybQsk?si=vYQqlLS9HVD36rXo",
      "https://www.youtube.com/embed/fKlqvByBGM0?si=Hhle4SMXjo2Skef_",
      // "https://www.youtube.com/watch?v=video_id3",
    ],
    visible: true,
  },
  {
    id: 12,
    nombre: "Parrita",
    imagen: "/images/parrita.jpg",
    eslogan: "PARRITAESLOGAN",
    descripcion: "PARRITADESCRIPTION",
    biografia: "PARRITABIOGRAPHY",
    discografia: [
      // "url_imagen_album1.jpg",
      // "url_imagen_album2.jpg",
      // "url_imagen_album3.jpg",
    ],
    videografia: [
      // "https://www.youtube.com/watch?v=video_id1",
      // "https://www.youtube.com/watch?v=video_id2",
      // "https://www.youtube.com/watch?v=video_id3",
    ],
    visible: true,
  },
  {
    id: 13,
    nombre: "LaVenta",
    imagen: "/images/LaVenta.jpg",
    descripcion: "LAVENTADESCRIPTION",
    eslogan: "LAVENTAESLOGAN",
    biografia: "LAVENTABIOGRAPHY",
    discografia: [
      // "url_imagen_album1.jpg",
      // "url_imagen_album2.jpg",
      // "url_imagen_album3.jpg",
    ],
    videografia: [
      // "https://www.youtube.com/watch?v=video_id1",
      // "https://www.youtube.com/watch?v=video_id2",
      // "https://www.youtube.com/watch?v=video_id3",
    ],
    visible: true,
  },
  {
    id: 14,
    nombre: "Pata Negra",
    imagen: "/images/PataNegra.jpeg",
    descripcion: "LAVENTADESCRIPTION",
    eslogan: "PATANEGRAESLOGAN",
    biografia: "PATANEGRABIOGRAPHY",
    discografia: [
      "/images/loschorbos/Disco1PataNegra.jpg",
      "/images/loschorbos/DiscoPataNegra2.jpg",
      // "url_imagen_album3.jpg",
    ],
    videografia: [
      "https://www.youtube.com/embed/O5LC8BXPzOs?si=A-F86FB4sbYpv2x0",
      "https://www.youtube.com/embed/2c2mx_NnHd0?si=U9nh1yoYcaD-Ax-K",
      "https://www.youtube.com/embed/O5LC8BXPzOs?si=7bMv9sIdkjMsA-cl",
    ],
    visible: true,
  },
  {
    id: 15,
    nombre: "Sorderita",
    imagen: "/images/Sorderita.jpeg",
    descripcion: "SORDERITADESCRIPTION",
    eslogan: "SORDERITAESLOGAN",
    biografia: "SORDERITABIOGRAPHY",
    discografia: [
      "/images/loschorbos/Sorderita1.jpg",
      "/images/loschorbos/Sorderita2.jpg",
      "/images/loschorbos/Sorderita3.jpg",
    ],
    videografia: [
      "https://www.youtube.com/embed/GzNIT5ShZE8",
      "https://www.youtube.com/embed/pb9GEPSZhcs",
      "https://www.youtube.com/embed/hme4BnPLYcY"
    ],
    visible: true,
  },
  {
    id: 16,
    nombre: "Gipsy Queens",
    imagen: "/images/GipsyQueens.jpeg",
    descripcion: "LAVENTADESCRIPTION",
    biografia:
      "Manzanita nació en Algeciras y proviene de una familia de artistas flamencos. Durante su carrera, grabó numerosos discos y colaboró con reconocidos artistas del panorama musical.",
    discografia: [
      "url_imagen_album1.jpg",
      "url_imagen_album2.jpg",
      "url_imagen_album3.jpg",
    ],
    videografia: [
      "https://www.youtube.com/watch?v=video_id1",
      "https://www.youtube.com/watch?v=video_id2",
      "https://www.youtube.com/watch?v=video_id3",
    ],
    visible: false,
  },
  {
    id: 17,
    nombre: "Jose el frances",
    imagen: "/images/JoseFrances.jpeg",
    descripcion: "JOSEFRANCESDESCRIPTION",
    eslogan: "JOSEFRANCESESLOGAN",
    biografia: "JOSEFRANCESBIOGRAPHY",
    discografia: [
      "/images/loschorbos/JoseFrances1.jpg",
      "/images/loschorbos/JoseFrances2.jpg",
      "/images/loschorbos/JoseFrances3.jpg",
    ],
    videografia: [
      "https://www.youtube.com/embed/AFyx7W4NL1Q",
      "https://www.youtube.com/embed/bup0HPwYxt8",
      "https://www.youtube.com/embed/fOKvikCuG4s",
    ],
    visible: true,
  },
  {
    id: 18,
    nombre: "Ketama",
    imagen: "/images/Ketama.jpeg",
    descripcion: "KETAMADESCRIPTION",
    eslogan: "KETAMAESLOGAN",
    biografia: "KETAMABIOGRAPHY",
    discografia: [
      "/images/loschorbos/ketama1.jpg",
      "/images/loschorbos/ketama2.jpg",
      "/images/loschorbos/ketama3.jpg",
    ],
    videografia: [
      "https://www.youtube.com/embed/dppoKI22drc",
      "https://www.youtube.com/embed/BYt-Wwniv5c",
      "https://www.youtube.com/embed/nDa6wYf4a-A",
    ],
    visible: true,
  },
  {
    id: 19,
    nombre: "Camarón",
    imagen: "/images/Camaron.jpg",
    descripcion: "CAMARONDESCRIPTION",
    eslogan: "CAMARONESLOGAN",
    biografia: "CAMARONBIOGRAPHY",
    discografia: [
      "/images/loschorbos/Camaron1.jpg",
      "/images/loschorbos/Camaron2.jpg",
      "/images/loschorbos/Camaron3.jpg"
    ],
    videografia: [
      "https://www.youtube.com/embed/goDULUEyqNM",
      "https://www.youtube.com/embed/LhTUzleCwZc",
      "https://www.youtube.com/embed/jhIIO4mELbs",
    ],
    visible: true,
  },
  {
    id: 20,
    nombre: "La Zimbra",
    imagen: "/images/LaZimbra.jpg",
    descripcion: "ZIMBRADESCRIPTION",
    eslogan: "ZIMBRAESLOGAN",
    biografia: "ZIMBRABIOGRAPHY",
    discografia: [
      // "url_imagen_album1.jpg",
      // "url_imagen_album2.jpg",
      // "url_imagen_album3.jpg",
    ],
    videografia: [
      // "https://www.youtube.com/watch?v=video_id1",
      // "https://www.youtube.com/watch?v=video_id2",
      // "https://www.youtube.com/watch?v=video_id3",
    ],
    visible: true,
  },
];
