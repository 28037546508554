import React, { useEffect } from "react";
import "./ArtistDetail.css";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Section from "../../components/Section/Section";
import SpacedText from "../../components/SpacedText/SpacedText";
import Slideshow from "../../components/Slideshow/Slideshow";
import { data } from "../../artists";
import ScrollToTopButton from "../../components/ScrollToTopButton/ScrollToTopButton";
import ColorSection from "../../components/ColorSection/ColorSection";
import Videography from "../../components/Videography/Videography";

const ArtistDetail = () => {
  const { id } = useParams();
  let artist = data.find((artist) => artist.id == id);

  const ArtistDetailContent = ({ artist }) => {
    const { t } = useTranslation();

    const eslogan = t(artist.eslogan);

    return (
      <div className="artistDetail">
        {eslogan.split(" ").length > 2 && (
          <div className="eslogan">
            <SpacedText content={t(artist.eslogan)}></SpacedText>
          </div>
        )}

        <div className="portadaDetail">
          <img src={artist.imagen} alt="portada" />
        </div>

        <div>
          <h1>{t("DESCRIPTION")}</h1>
          <ColorSection text={t(artist.descripcion)}></ColorSection>
          <hr />
          <h1>{t("BIOGRAPHY")}</h1>
          <SpacedText content={t(artist.biografia)}></SpacedText>

          {artist?.discografia?.length > 0 ? (
            <>
              <hr />
              <h1>{t("DISCOGRAPHY")}</h1>
              <Slideshow
                images={artist.discografia}
                intervalTime={4500}
              ></Slideshow>
            </>
          ) : (
            <></>
          )}
          {artist?.videografia?.length > 0 ? (
            <>
              <hr />
              <h1>{t("VIDEOGRAPHY")}</h1>
              <Videography data={artist}></Videography>
              {/* <Carousel images={artist.discografia}></Carousel> */}
            </>
          ) : (
            <></>
          )}
        </div>

        <div className="volverAtrasContainer">
          <a className="volverAtras" href="/artists">
            {t("GOBACK")}{" "}
          </a>
        </div>
      </div>
    );
  };

  return (
    <>
      <Navbar></Navbar>
      <Section
        title={artist.nombre}
        content={<ArtistDetailContent artist={artist}></ArtistDetailContent>}
      ></Section>
      <ScrollToTopButton></ScrollToTopButton>
      <Footer></Footer>
    </>
  );
};

export default ArtistDetail;
